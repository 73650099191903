import { ChangeEvent, useState } from "react";
import Alert from "../../../../components/alert";
import Button from "../../../../components/button";
import { InputField } from "../../../../components/input-functions";
import Modal from "../../../../components/modal";
import { EnterpriseDetailsV1 } from "../../../../dto/enterprise.dto";
import { useApplicationSuccessModal } from "../../../../hooks/wctl/useApplicationSuccessModal";
import { useWctlApplicationForm } from "../../../../hooks/wctl/useWctlApplicationForm";
import { ReactComponent as Tick } from "../../../../images/green-tick.svg";
import { ReactComponent as LoadingIcon } from "../../../../images/loader.svg";
import { CreateWctlApplication } from "../../../../models";

interface WctlApplicationProps {
  title: string;
  singleEnterprise: EnterpriseDetailsV1;
}

interface CreateWctlApplicationFormProps {
  singleEnterprise: EnterpriseDetailsV1;
  formData: CreateWctlApplication;
  onDissClick: () => void;
  errorMessage: string;
  onFormDataChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    transformValue?: (value: string) => void
  ) => void;
}

interface ApplicationCreatedSuccessProps {
  message: string;
}

export default function WctlApplication({
  title,
  singleEnterprise,
}: WctlApplicationProps) {
  const {
    formData,
    errorMessage,
    isFormValid,
    handleChange,
    handleSubmit,
    setErrorMessage,
  } = useWctlApplicationForm(singleEnterprise);

  const {
    applicationCreatedSuccessModal,
    showSuccessModal,
    closeSuccessModal,
  } = useApplicationSuccessModal();

  const [isLoading, setIsLoading] = useState(false);
  const [isFormModalActive, setIsFormModalActive] = useState(false);

  const handleFormSubmit = async () => {
    setIsLoading(true);
    const result = await handleSubmit();
    if (result && result.success) {
      setIsFormModalActive(false);
      showSuccessModal(result.message);
    }
    setIsLoading(false);
  };

  if (isLoading)
    return (
      <div className="d-flex align-items-center justify-content-center">
        <LoadingIcon />
      </div>
    );

  return (
    <>
      <div className="dashboard-container d-flex align-content-center justify-content-between">
        <h3 className="user-page-title">{title}</h3>
        <Button size="md" onClick={() => setIsFormModalActive(true)}>
          Create application
        </Button>
      </div>
      <Modal
        open={isFormModalActive}
        onClose={() => setIsFormModalActive(false)}
        title="Create WCTL Application"
        size="large"
        hasCancelButton={false}
        submitButtonText="Create Application"
        onSubmit={handleFormSubmit}
        submitButtonDisable={!isFormValid}
        children={
          <CreateWctlApplicationForm
            singleEnterprise={singleEnterprise}
            formData={formData}
            errorMessage={errorMessage}
            onFormDataChange={handleChange}
            onDissClick={() => setErrorMessage("")}
          />
        }
      />
      <Modal
        open={applicationCreatedSuccessModal.active}
        onClose={closeSuccessModal}
        title="WCTL Application Created Successfully"
        hasCancelButton={false}
        hasSubmitButton={false}
        children={
          <ApplicationCreatedSuccess
            message={applicationCreatedSuccessModal.message}
          />
        }
      />
    </>
  );
}

function CreateWctlApplicationForm({
  singleEnterprise,
  errorMessage,
  onDissClick,
  onFormDataChange,
  formData,
}: CreateWctlApplicationFormProps) {
  return (
    <form>
      <Alert
        type="danger"
        message={errorMessage}
        canDismiss={true}
        onDismissClick={onDissClick}
      />

      <InputField
        label="Enterprise ID"
        type="text"
        name="enterpriseId"
        placeholder="Enterprise ID"
        value={singleEnterprise?._id}
        disabled
        required
      />

      <InputField
        label="Product ID"
        type="text"
        name="productId"
        placeholder="Product ID"
        value={formData.productId}
        onChange={onFormDataChange}
        required
      />

      <InputField
        label="IRPA"
        type="text"
        inputMode="numeric"
        name="irpa"
        placeholder="irpa"
        value={formData.irpa}
        onChange={onFormDataChange}
        required
      />

      <InputField
        label="PF"
        type="text"
        inputMode="numeric"
        name="pf"
        placeholder="irpa"
        value={formData.pf}
        onChange={onFormDataChange}
        required
      />

      <InputField
        label="TDS Deducted"
        type="text"
        inputMode="numeric"
        name="tdsDeducted"
        placeholder="Tds Deducted"
        value={formData.tdsDeducted}
        onChange={onFormDataChange}
      />

      <InputField
        label="Approved Amount"
        type="text"
        inputMode="numeric"
        name="approvedAmount"
        placeholder="Approved Amount"
        value={formData.approvedAmount}
        onChange={onFormDataChange}
        required
      />

      <InputField
        label="Tenure In Months"
        type="text"
        inputMode="numeric"
        name="tenureInMonths"
        placeholder="Tenure In Months"
        value={formData.tenureInMonths}
        onChange={onFormDataChange}
        required
      />

      <InputField
        label="Morotorium In Months"
        type="text"
        inputMode="numeric"
        name="moratoriumInMonths"
        placeholder="Morotorium In Months"
        value={formData.moratoriumInMonths}
        onChange={onFormDataChange}
        required
      />
      <InputField
        label="Application Date"
        type="date"
        name="applicationDate"
        placeholder="Application Date"
        value={formData.applicationDate}
        onChange={onFormDataChange}
      />
    </form>
  );
}

function ApplicationCreatedSuccess({
  message,
}: ApplicationCreatedSuccessProps) {
  return (
    <div className="d-flex flex-column align-items-center">
      <Tick width={64} height={64} />
      <p className="mt-4">{message}</p>
    </div>
  );
}

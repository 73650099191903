import Table, { TableColumns } from "../../../components/table";

interface SingleEnterpriseWctlApplicationTableProps {
  loading: boolean;
  title: string;
  data: {
    _id: JSX.Element;
    irpa: number;
    approvedAmount: number;
  }[];
  columns: TableColumns[];
}

export default function SingleEnterpriseWctlApplicationTable({
  loading,
  data,
  title,
  columns,
}: SingleEnterpriseWctlApplicationTableProps) {
  return (
    <div className="dashboard-container">
      <h3 className="user-page-title mb-4">{title}</h3>
      <Table loading={loading} columns={columns} data={data} />
    </div>
  );
}

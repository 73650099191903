import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getClosingBalanceFinancialStats,
  getCommercialOdFinancialStats,
  getMultiMonthFinacialStats,
  getOneMonthFinancialStats,
  getSummaryStats,
} from "../../actions/admin.action";
import DeltaIndicatorChip, {
  DeltaIndicatorType,
} from "../../components/delta-indicator-chip";
import InfoCard, { InfoCardVariants } from "../../components/info-card";
import {
  ClosingBalanceFinancialStats,
  CommercialODFinancialStats,
  MultiMonthFinancialStats,
  OneMonthFinancialStats,
  SummaryStats,
} from "../../dto";
import useScreenName from "../../hooks/useScreenName";
import { ReactComponent as LoaderIcon } from "../../images/loader.svg";
import dispatch, { MiddlewareAction } from "../../middleware";
import { RoutePaths } from "../../models";
import { ReduxState } from "../../reducers";
import { dateToDDMMYYYYHHMMAP, isLoadingActive } from "../../utils";
import "./dashboardStyles.css";

function renderContentElem(context: {
  prefix?: string;
  content: string | number;
  delta?: { type: DeltaIndicatorType; value: string };
}) {
  const { prefix, content, delta } = context;

  // TODO: handle NaN case
  const contentAsFormattedNumber =
    typeof content === "number"
      ? Number(content).toLocaleString("en-IN", {
          maximumFractionDigits: 0,
        })
      : content;

  const textContent = prefix
    ? `${prefix} ${contentAsFormattedNumber}`
    : contentAsFormattedNumber;
  if (!delta) return textContent;
  return (
    <>
      <span className="mr-2">{textContent}</span>
      <DeltaIndicatorChip {...delta} />
    </>
  );
}
function getInfoCardVariantByDelta(
  deltaType?: DeltaIndicatorType
): InfoCardVariants {
  if (deltaType === "increment") return "success";
  if (deltaType === "decrement") return "warning";
  return "info";
}

function getDeltaFromNumber(
  increment: number = 0
): { type: DeltaIndicatorType; value: string } | undefined {
  if (increment === 0) return;
  const deltaType: DeltaIndicatorType =
    increment > 0 ? "increment" : "decrement";
  return { type: deltaType, value: `${Math.abs(increment)}%` };
}

interface InfoCardSection {
  sectionName: string;
  lastUpdatedAt?: string;
  filterDate?: string;
  cards: Array<{
    label: string;
    content: string | number;
    delta?: { type: DeltaIndicatorType; value: string };
    navigateTo?: RoutePaths;
  }>;
}

function getMonthTypeInputValueFromDate(date: Date | null): string {
  if (!date) return "";
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const monthWithPaddingAtStart = month.toString().padStart(2, "0");
  return `${year}-${monthWithPaddingAtStart}`;
}

export default function DashboardScreen() {
  useScreenName("Dashboard");
  const storeDispatch = useDispatch();
  const history = useHistory();
  const loadingQueue = useSelector(
    (state: ReduxState) => state.admin.loadingQueue
  );
  const loading = isLoadingActive(loadingQueue);
  const summaryStats: SummaryStats | null = useSelector(
    (state: ReduxState) => state.admin.summaryStats
  );
  const oneMonthFinancialStats: OneMonthFinancialStats | null = useSelector(
    (state: ReduxState) => state.admin.oneMonthFinancialStats
  );

  const multiMonthFinancialStats: MultiMonthFinancialStats | null = useSelector(
    (state: ReduxState) => state.admin.multiMonthFinancialStats
  );

  const commercialOdFinancialStats: CommercialODFinancialStats | null =
    useSelector((state: ReduxState) => state.admin.commercialOdFinancialStats);

  const closingBalancesFinancialStats: ClosingBalanceFinancialStats | null =
    useSelector(
      (state: ReduxState) => state.admin.closingBalanceFinancialStats
    );

  const [selectedMonthAndYear, updateSelectedMonthAndYear] =
    useState<Date | null>(new Date());

  function createFinancialStatsFetcher(
    actionCreator: (month: string, year: string) => MiddlewareAction
  ) {
    return async function (date: Date | null) {
      try {
        const monthYear = getMonthTypeInputValueFromDate(date);
        if (!monthYear) return;
        const [year, month] = monthYear.split("-");
        await dispatch(storeDispatch, actionCreator(month, year));
      } catch (_) {}
    };
  }

  const fetchOneMonthFinancialStats = createFinancialStatsFetcher(
    getOneMonthFinancialStats
  );
  const fetchMultiMonthFinancialStats = createFinancialStatsFetcher(
    getMultiMonthFinacialStats
  );
  const fetchCommercialOdFinancialStats = createFinancialStatsFetcher(
    getCommercialOdFinancialStats
  );
  const fetchClosingBalancesFinancialStats = createFinancialStatsFetcher(
    getClosingBalanceFinancialStats
  );

  const overallTotalDisbursedAmount = useMemo(() => {
    if (
      oneMonthFinancialStats?.data?.totalDisbursedAmount !== undefined &&
      multiMonthFinancialStats?.data?.totalDisbursedAmount !== undefined &&
      commercialOdFinancialStats?.data?.totalDisbursedAmount !== undefined
    ) {
      return (
        oneMonthFinancialStats.data.totalDisbursedAmount +
        multiMonthFinancialStats.data.totalDisbursedAmount +
        commercialOdFinancialStats.data.totalDisbursedAmount
      );
    }
  }, [
    oneMonthFinancialStats?.data?.totalDisbursedAmount,
    multiMonthFinancialStats?.data?.totalDisbursedAmount,
    commercialOdFinancialStats?.data?.totalDisbursedAmount,
  ]);

  const overallTotalCollections = useMemo(() => {
    if (
      oneMonthFinancialStats?.data.totalRepaidAmount !== undefined &&
      multiMonthFinancialStats?.data.totalRepaidAmount !== undefined &&
      commercialOdFinancialStats?.data.totalPrincipalRepaid !== undefined &&
      commercialOdFinancialStats.data.totalInterestRepaid !== undefined
    ) {
      return (
        oneMonthFinancialStats?.data.totalRepaidAmount +
        multiMonthFinancialStats?.data.totalRepaidAmount +
        commercialOdFinancialStats?.data.totalPrincipalRepaid +
        commercialOdFinancialStats.data.totalInterestRepaid
      );
    }
  }, [
    oneMonthFinancialStats?.data.totalRepaidAmount,
    multiMonthFinancialStats?.data.totalRepaidAmount,
    commercialOdFinancialStats?.data.totalPrincipalRepaid,
    commercialOdFinancialStats?.data.totalInterestRepaid,
  ]);

  const overallTotalProcessingFees = useMemo(() => {
    if (
      oneMonthFinancialStats?.data.totalProcessingFee !== undefined &&
      multiMonthFinancialStats?.data.totalProcessingFee !== undefined &&
      commercialOdFinancialStats?.data.totalProcessingFeeCharged !== undefined
    ) {
      return (
        oneMonthFinancialStats?.data.totalProcessingFee +
        multiMonthFinancialStats?.data.totalProcessingFee +
        commercialOdFinancialStats?.data.totalProcessingFeeCharged
      );
    }
  }, [
    oneMonthFinancialStats?.data.totalProcessingFee,
    multiMonthFinancialStats?.data.totalProcessingFee,
    commercialOdFinancialStats?.data.totalProcessingFeeCharged,
  ]);

  const overallTotalInterestEarned = useMemo(() => {
    if (
      oneMonthFinancialStats?.data.totalRepaidInterest !== undefined &&
      multiMonthFinancialStats?.data.totalRepaidInterest !== undefined &&
      commercialOdFinancialStats?.data.totalInterestRepaid !== undefined
    ) {
      return (
        oneMonthFinancialStats?.data.totalRepaidInterest +
        multiMonthFinancialStats?.data.totalRepaidInterest +
        commercialOdFinancialStats?.data.totalInterestRepaid
      );
    }
  }, [
    oneMonthFinancialStats?.data.totalRepaidInterest,
    multiMonthFinancialStats?.data.totalRepaidInterest,
    commercialOdFinancialStats?.data.totalInterestRepaid,
  ]);

  const overallTotalTdsDeducted =
    commercialOdFinancialStats?.data.totalTdsChargedOnInterest !== undefined &&
    commercialOdFinancialStats?.data.totalTdsChargedOnProcessingFee !==
      undefined
      ? commercialOdFinancialStats?.data.totalTdsChargedOnInterest +
        commercialOdFinancialStats?.data.totalTdsChargedOnProcessingFee
      : undefined;

  useEffect(() => {
    dispatch(storeDispatch, getSummaryStats()).catch((_) => {});
  }, []);

  useEffect(() => {
    Promise.allSettled([
      fetchOneMonthFinancialStats(selectedMonthAndYear),
      fetchMultiMonthFinancialStats(selectedMonthAndYear),
      fetchCommercialOdFinancialStats(selectedMonthAndYear),
      fetchClosingBalancesFinancialStats(selectedMonthAndYear),
    ]);
  }, [selectedMonthAndYear]);

  function handleCardNavigateClick(routePath?: RoutePaths) {
    if (!routePath) return;
    return () => history.push(routePath);
  }

  const INFO_CARDS: InfoCardSection[] = [
    {
      sectionName: "Summary",
      lastUpdatedAt: summaryStats?.metadata.cacheLastUpdatedAt,
      cards: [
        {
          label: "Users",
          content: summaryStats?.data.totalUsers || 0,
          navigateTo: RoutePaths.USERS,
          delta: getDeltaFromNumber(summaryStats?.data.usersIncrementPercent),
        },
        {
          label: "Applications",
          content: summaryStats?.data.totalApps || 0,
          navigateTo: RoutePaths.APPLICATIONS,
          delta: getDeltaFromNumber(summaryStats?.data.appsIncrementPercent),
        },
        {
          label: "Loans",
          content: summaryStats?.data.totalLoans || 0,
          navigateTo: RoutePaths.LOANS,
          delta: getDeltaFromNumber(summaryStats?.data.loansIncrementPercent),
        },
      ],
    },
    {
      sectionName: "Overall Monthly Financials",
      filterDate: "Date",
      cards: [
        {
          label: "Total Disbursals",
          content:
            overallTotalDisbursedAmount !== undefined
              ? overallTotalDisbursedAmount
              : "-",
        },
        {
          label: "Total Collections",
          content:
            overallTotalCollections !== undefined
              ? overallTotalCollections
              : "-",
        },
        {
          label: "Processing Fees",
          content:
            overallTotalProcessingFees !== undefined
              ? overallTotalProcessingFees
              : "-",
        },
        {
          label: "Interest Earned",
          content:
            overallTotalInterestEarned !== undefined
              ? overallTotalInterestEarned
              : "-",
        },
        {
          label: "Closing Balance",
          content:
            (closingBalancesFinancialStats &&
              closingBalancesFinancialStats.closingBalance) ??
            "-",
        },
        {
          label: "TDS Deducted",
          content:
            overallTotalTdsDeducted !== undefined
              ? overallTotalTdsDeducted
              : "-",
        },
      ],
    },
    {
      sectionName: "1 Month Based Loans",
      cards: [
        {
          label: "Total Disbursals",
          content: oneMonthFinancialStats
            ? oneMonthFinancialStats.data.totalDisbursedAmount
            : "-",
        },
        {
          label: "Total Collections",
          content: oneMonthFinancialStats
            ? oneMonthFinancialStats.data.totalRepaidAmount
            : "-",
        },
        {
          label: "Processing Fees",
          content: oneMonthFinancialStats
            ? oneMonthFinancialStats.data.totalProcessingFee
            : "-",
        },
        {
          label: "Interest Earned",
          content: oneMonthFinancialStats
            ? oneMonthFinancialStats.data.totalRepaidInterest
            : "-",
        },
      ],
    },
    {
      sectionName: "> 1 Month Based Loans",
      cards: [
        {
          label: "Total Disbursals",
          content: multiMonthFinancialStats
            ? multiMonthFinancialStats.data.totalDisbursedAmount
            : "-",
        },
        {
          label: "Total Collections",
          content: multiMonthFinancialStats
            ? multiMonthFinancialStats.data.totalRepaidAmount
            : "-",
        },
        {
          label: "Processing Fees",
          content: multiMonthFinancialStats
            ? multiMonthFinancialStats.data.totalProcessingFee
            : "-",
        },
        {
          label: "Interest Earned",
          content: multiMonthFinancialStats
            ? multiMonthFinancialStats.data.totalRepaidInterest
            : "-",
        },
      ],
    },
    {
      sectionName: "B2B Lending",
      cards: [
        {
          label: "New Sanctioned Loans",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalSanctionedLoans
            : "-",
        },
        {
          label: "New Sanctioned Amount",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalSanctionedAmount
            : "-",
        },
        {
          label: "Total Amount Disbursed",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalDisbursedAmount
            : "-",
        },
        {
          label: "Total Principal Repaid",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalPrincipalRepaid
            : "-",
        },
        {
          label: "Total Interest Repaid",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalInterestRepaid
            : "-",
        },
        {
          label: "Total Processing Fee",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalProcessingFeeCharged
            : "-",
        },
        {
          label: "TDS Deducted -  PF",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalTdsChargedOnProcessingFee
            : "-",
        },
        {
          label: "TDS Deducted - Interest",
          content: commercialOdFinancialStats
            ? commercialOdFinancialStats.data.totalTdsChargedOnInterest
            : "-",
        },
      ],
    },
  ];

  return (
    <>
      {loading ? (
        <div className="text-center my-5">
          <LoaderIcon />
        </div>
      ) : (
        INFO_CARDS.map(
          ({ sectionName, lastUpdatedAt, cards, filterDate }, index, arr) => (
            <section
              key={index}
              className={`${index < arr.length - 1 ? "mb-5" : ""}`}
            >
              <div className="d-flex align-items-center justify-content-between mb-4">
                <h3 className="user-page-title">{sectionName}</h3>
                {lastUpdatedAt && (
                  <span className="small">
                    Last Updated At: {dateToDDMMYYYYHHMMAP(lastUpdatedAt)}
                  </span>
                )}
                {filterDate && (
                  <div className="d-flex flex-lg-row flex-column align-items-lg-end">
                    <input
                      type="month"
                      id="fromDate"
                      className="form-control input-login"
                      placeholder="From Date"
                      value={getMonthTypeInputValueFromDate(
                        selectedMonthAndYear
                      )}
                      onChange={(e) =>
                        updateSelectedMonthAndYear(e.currentTarget.valueAsDate)
                      }
                      required
                    />
                  </div>
                )}
              </div>
              <div className="row">
                {cards.map((card, currentCardIndex) => (
                  <div
                    key={currentCardIndex}
                    className={`col-lg-4 mb-4 ${
                      currentCardIndex > 0 ? "mt-4 mt-lg-0" : ""
                    }`}
                  >
                    <InfoCard
                      variant={getInfoCardVariantByDelta(card.delta?.type)}
                      label={card.label}
                      content={renderContentElem({
                        content: card.content,
                        delta: card.delta,
                      })}
                      onClick={handleCardNavigateClick(card.navigateTo)}
                    />
                  </div>
                ))}
              </div>
            </section>
          )
        )
      )}
    </>
  );
}

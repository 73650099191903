import type { ReactNode } from "react";
import { UserDocType } from "../dto";
import { BankAccountType } from "../dto/enterprise.dto";

export enum FieldName {
  EMAIL = "email",
  PASSWORD = "password",
  NAME = "name",
  ADDRESS = "address",
  RE_ENTER_PW = "reEnteredPw",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  EMPLOYER_ID = "employer_id",
  MOBILE = "mobile",
  UPI_ID = "upi_id",
}

export interface IValidation {
  status: boolean;
  msg: string;
  type: string;
}

export interface UserName {
  data: string;
  reviewMetadata: ReviewMetadata;
}
export interface UserParentOrSpouseName extends UserName {}
export interface UserEmail {
  data: string;
}
export interface UserPhone {
  data: { countryCode: string; number: string };
  reviewMetadata: ReviewMetadata;
}
export interface UserPan {
  data: { pan: string };
  reviewMetadata: ReviewMetadata;
}
export interface UserDob {
  data: number;
  reviewMetadata: ReviewMetadata;
}

export interface UserCkyc {
  data: {
    ckycId?: string;
  };
}
export interface UserBankInfo {
  data: {
    accountNumber: string;
    ifsc: string;
    primary: boolean;
    bankAccountType?: BankAccountType;
  };
  reviewMetadata: ReviewMetadata;
}
export interface UserAddress {
  data: {
    houseInfo: string;
    localityInfo: string;
    pinCode: string;
    city: string;
    state: string;
  };
  reviewMetadata: ReviewMetadata;
}
export interface UserAadhar {
  data: { aadhaar: string };
  reviewMetadata: ReviewMetadata;
}
export interface UserDrivingLicense {
  data: { drivingLicenseNumber: string };
  reviewMetadata: ReviewMetadata;
}
export interface UserVoterId {
  data: { voterIdNumber: string };
  reviewMetadata: ReviewMetadata;
}
export interface UserPassport {
  data: { passportNumber: string };
  reviewMetadata: ReviewMetadata;
}
export interface UserWorkAddress {
  addressLine: string;
  pinCode: string;
  city: string;
  state: string;
  country?: string;
}

export enum CompanyTypeEnum {
  PRIVATE_LIMITED = "PRIVATE_LIMITED",
  PUBLIC_LIMITED = "PUBLIC_LIMITED",
  PUBLIC_SECTOR_UNDERTAKING = "PUBLIC_SECTOR_UNDERTAKING",
  ONE_PERSON_COMPANY = "ONE_PERSON_COMPANY",
}
export enum EmploymentTypeEnum {
  SALARIED = "SALARIED",
  SELF_EMPLOYED = "SELF_EMPLOYED",
  PROFESSIONAL = "PROFESSIONAL",
}
export interface UserWorkInfo {
  data: {
    employmentType: EmploymentTypeEnum;
    employerName: string;
    companyType: CompanyTypeEnum;
    designation: string;
    currentMonthlySalary: number;
    workEmail: string;
    workMobile: string;
    workAddress: UserWorkAddress;
    isLatest: boolean;
  };
  reviewMetadata: ReviewMetadata;
}

export interface NewUser {
  name: string;
  clientUniqueRefId?: string;
  email?: string;
  phone?: string;
  pan: string;
  source?: string;
}

export type GenderType = "MALE" | "FEMALE" | "OTHERS";

export interface UpdateUser {
  userId: string;
  parentOrSpouseName: string;
  address: {
    houseInfo: string;
    localityInfo: string;
    pinCode: string;
    city: string;
    state: string;
  };
  bankAccountInfo: {
    accountNumber: string;
    ifsc: string;
  };
  dob: string;
  email: string;
  phone: string;
  // "" (empty string) is required to show the <select /> placeholder when the value is falsy
  gender: GenderType | "";
  pan?: string;
  aadhaarNumber?: string;
  passportNumber?: string;
  drivingLicenseNumber?: string;
  voterIdNumber?: string;
  identityProofType?: UserDocumentKey | null;
  addressProofType?: UserDocumentKey | null;
  workInfo: {
    employmentType: EmploymentTypeEnum | "";
    employerName: string;
    companyType: CompanyTypeEnum | "";
    designation: string;
    currentMonthlySalary?: number | string;
    workEmail: string;
    workMobile: string;
    workAddress: UserWorkAddress;
  };
}

export interface VerifyUser {
  userId: string;
  pan: VerificationMetadata;
  bankAccount: VerificationMetadata;
  dob: VerificationMetadata;
  name: VerificationMetadata;
  address: VerificationMetadata;
}

export interface StoreUserDoc {
  file: string;
  documentName: string;
  userId: string;
  contentType: UploadDocType;
  documentType?: UserDocumentTypes;
  documentCategory?: UserDocCategory[];
}

export interface DocumentInfo {
  url: string;
  versionId: string;
  mimeType: string;
  createdAt: Date;
}

export type VerificationStatus = "PENDING" | "VERIFIED" | "INCORRECT";

export interface VerificationMetadata {
  status: VerificationStatus;
  statusReason: string;
}

export interface ReviewMetadata {
  status: VerificationStatus;
  actorEmailId?: string;
  lastVerifiedAt?: Date;
  statusReason?: string;
  verifiedBy?: string;
}

export type UploadDocType = "image/png" | "image/jpeg" | "application/pdf";

export type UserStatus = "PENDING" | "VERIFIED" | "REJECTED";

export enum UserDocumentTypes {
  PAN = "PAN",
  DRIVING_LICENSE = "DRIVING_LICENSE",
  VOTER_ID = "VOTER_ID",
  HR_LETTER = "HR_LETTER",
  RENTAL_AGREEMENT = "RENTAL_AGREEMENT",
  SELFIE = "SELFIE",
  AADHAAR_CARD = "AADHAAR_CARD",
  PASSPORT = "PASSPORT",
  EKYC = "EKYC",
}

export enum UserDocumentKey {
  PAN = "pan",
  AADHAAR_CARD = "aadhaarNumber",
  PASSPORT = "passportNumber",
  DRIVING_LICENSE = "drivingLicenseNumber",
  VOTER_ID = "voterIdNumber",
}

export enum CommercialODApplicationDocsEnum {
  SIGNED_LENDER_AGREEMENT = "SIGNED_LENDER_AGREEMENT",
  BANK_STATEMENT = "BANK_STATEMENT",
  ITR = "ITR",
  PROVISIONAL_OF_FINANCIALS = "PROVISIONAL_OF_FINANCIALS",
  GST_DOCUMENTS = "GST_DOCUMENTS",
  PAYROLL_DETAILS = "PAYROLL_DETAILS",
  INCORPORATION_CERTIFICATE = "INCORPORATION_CERTIFICATE",
  LOAN_DETAILS_WITH_REPAYMENT_SCHEDULES = "LOAN_DETAILS_WITH_REPAYMENT_SCHEDULES",
  DEBTOR_DETAILS = "DEBTOR_DETAILS",
  OD_STATEMENT = "OD_STATEMENT",
  FINANCIAL_STATEMENTS = "FINANCIAL_STATEMENTS",
}

export const userDocKeyAndTypeMap: Record<UserDocumentKey, UserDocumentTypes> =
  {
    [UserDocumentKey.PAN]: UserDocumentTypes.PAN,
    [UserDocumentKey.AADHAAR_CARD]: UserDocumentTypes.AADHAAR_CARD,
    [UserDocumentKey.PASSPORT]: UserDocumentTypes.PASSPORT,
    [UserDocumentKey.DRIVING_LICENSE]: UserDocumentTypes.DRIVING_LICENSE,
    [UserDocumentKey.VOTER_ID]: UserDocumentTypes.VOTER_ID,
  };

export enum UserDocCategory {
  IDENTITY_PROOF = "IDENTITY_PROOF",
  ADDRESS_PROOF = "ADDRESS_PROOF",
}

export enum CreditReportVendor {
  EQUIFAX = "EQUIFAX",
}
export interface CreditReportEnquiry {
  institution: string;
  enquiryDate: Date;
  enquiryTime: string;
  purpose?: string;
  amount: number;
}

export interface CreditReportPersonalInfo {
  fullName: string;
  dob?: Date;
  gender: GenderType;
  totalIncome?: number;
}

export interface CreditReportIdentityInfo {
  idCardType: IdentityCardTypeEnum;
  data: string;
  reportedDate?: Date;
}

export interface CreditReportAddressInfo {
  address: string;
  state: string;
  pincode: string;
  addressType?: string;
  reportedDate?: Date;
}

export interface CreditReportPhoneInfo {
  number: string;
  type: string;
  reportedDate?: Date;
}

export interface CreditReportEmailInfo {
  email: string;
  reportedDate?: Date;
}

export enum OwnershipTypeEnum {
  Individual = "Individual",
  "Authorized User" = "Authorized User",
  Guarantor = "Guarantor",
  Joint = "Joint",
}
export enum IdentityCardTypeEnum {
  AADHAR = "AADHAR",
  PAN = "PAN",
  VOTER_ID = "VOTER_ID",
  PASSPORT = "PASSPORT",
  RATION_CARD = "RATION_CARD",
  DL = "DL",
  OTHER = "OTHER",
}
export interface CreditReportAccountInfo {
  accountNumber: string;
  institution: string;
  accountType: string;
  status: string;
  ownershipType?: OwnershipTypeEnum;
  emiAmount?: number;
  balance?: number;
  tenure?: number;
  openedDate: Date;
}

export interface CreditReportAccountsSummary {
  noOfAccounts?: number;
  noOfActiveAccounts?: number;
  noOfWriteOffs?: number;
  totalBalanceAmount?: number;
  totalSanctionedAmount?: number;
  totalCreditLimit?: number;
  totalMonthlyPaymentAmount?: number;
}

export interface CreditReportScoreDetails {
  type: string;
  name: string;
  score: number;
  version: number;
}

export interface CreditReportEnquiriesSummary {
  total: number;
  past30Days?: number;
  past12Months?: number;
  past24Months?: number;
  recent?: Date;
}

export interface CreditReportOtherKeyIndicators {
  ageOfOldestTrade?: number;
  numberOfOpenTrades?: number;
  allLinesEverWritten?: number;
  allLinesEverWrittenIn9Months?: number;
  allLinesEverWrittenIn6Months?: number;
}

export interface CreditReportRecentActivities {
  accountsDeliquent?: number;
  accountsOpened?: number;
  totalInquiries?: number;
  accountsUpdated?: number;
}

export interface CreditReportError {
  ErrorCode: string;
  ErrorDesc: string;
}

export type ApplicationStatusType = "APPROVED" | "REJECTED" | "IN_PROGRESS";
export interface ApplicationStatusInfo {
  data: ApplicationStatusType;
  comment?: string;
  updatedAt?: Date;
}

export type AdminType = "ADMIN" | "SUPER_ADMIN";

export type PaymentMediumType = "BANK";

export enum CommercialOdLoanProductCategoryEnum {
  DROPLINE_OD = "DROPLINE_OD",
  PURCHASE_INVOICING = "PURCHASE_INVOICING",
  INVOICE_DISCOUNTING = "INVOICE_DISCOUNTING",
  PAYROLL_FINANCING = "PAYROLL_FINANCING",
}

export interface NewTermApplication {
  entityId: string;
  productName: string;
  productVersion: number;
  approvedAmount: number;
  tenureInMonths: number;
  irpa: number;
  pf: number;
  source?: string;
  applicantType: ApplicantType;
}

export interface NewCommercialODApplication {
  enterpriseId: string;
  sanctionedAmount: number;
  drawdownRepaymentPeriodInDays: number;
  interestRepaymentPeriodInDays: number;
  irpa: number;
  pfPostGst: number;
  onDate: Date;
  loanProductCategory: CommercialOdLoanProductCategoryEnum;
  tdsDeducted?: number;
  vendorId?: string;
  vendorName?: string;
  invoiceId?: string;
  invoiceAmount?: number;
  invoiceDate?: Date;
  discountDays?: number;
}

export interface CreateWctlApplication {
  productId: string;
  enterpriseId: string;
  irpa: number;
  pf: number;
  approvedAmount: number;
  tenureInMonths: number;
  tdsDeducted: number;
  moratoriumInMonths: number;
  applicationDate?: string;
}

export interface UpdateTermApplication {
  appId: string;
  approvedAmount?: number;
  irpa?: number;
  tenureInMonths?: number;
  pf: number;
}

export interface UpdateCommercialApplication {
  pfPostGst: number;
  sanctionedAmount: number;
  drawdownRepaymentPeriodInDays: number;
  interestRepaymentPeriodInDays: number;
  irpa: number;
}

export interface NewODApplication {
  entityId: string;
  productName: string;
  productVersion: number;
  maxAmount: number;
  maxValidMonths?: number;
  irpa: number;
  pf: number;
  source?: string;
  applicantType: ApplicantType;
}

export interface UpdateOdApplication {
  appId: string;
  maxAmount?: number;
  irpa?: number;
  maxValidMonths?: number;
  pf: number;
}

export interface NewApplication {
  userId: string;
  pan: string;
  productName: string;
  productVersion: number;
  amount: number;
  tenureInMonths: number;
}

export interface UpdateApplicationStatus {
  toStatus: ApplicationStatusType;
  appId: string;
  reason?: string;
  emiStartDate?: string;
  comment: string;
}

export interface StoreApplicationDoc {
  file: string;
  documentName: string;
  appId: string;
  password?: string;
  contentType: UploadDocType;
}

export type ApplicationType =
  | "TERM_LOAN_APP"
  | "RETAIL_OD_APP"
  | "COMMERCIAL_OD_APP"
  | "WORKING_CAPITAL_TERM_LOAN_APP";

export interface BaseApplication {
  _id: string;
  userId?: string;
  enterpriseId?: string;
  status: ApplicationStatusInfo;
  productId: string;
  statusLog: ApplicationStatusLog[];
  documents?: DocumentInfo[];
  lenderId: string;
  irpa: number;
  pfPostGst: number;
  applicationType: ApplicationType;
  pf: number;
  source?: string;
  applicantType?: ApplicantType;
  user?: {
    _id?: string;
    employerName?: string;
    employeeName?: string;
  };
  createdAt: string;
}

export interface ApplicationStatusLog {
  status: ApplicationStatusType;
  date: Date;
  actorEmailId: string;
  reason?: string;
}

export type ProductType = "TERM_LOAN" | "CREDIT_LINE" | "RETAIL_OVERDRAFT";

export interface Range<T = number> {
  min: T;
  max: T;
}

export interface AddNewProduct {
  productName: string;
  productVersion: number;
  productType: ProductType;
  lenderId: string;
  irpa: number;
  pfInAmount: number;
  pfInPct: number;
  amountRange: {
    min: number;
    max: number;
  };
  roi: {
    min: number;
    max: number;
  };
  tenureInMonthsRange: {
    min: number;
    max: number;
  };
  minCibilScore: number;
  minSalaryRequired: number;
}

export interface BaseProduct {
  _id: string;
  productName: string;
  productVersion: number;
  lenderId: string;
  irpaRange: Range;
  pfInPctRange?: Range;
  pfAmountInRange?: Range;
  productType: ProductType;
  amountRange?: Range;
}

export interface AddLoanPayment {
  loanId: string;
  amount: number;
  transactionRefNo: string;
  paidDate: string;
  emiNo?: number;
  medium: PaymentMediumType;
  txnFeeInAmount?: number;
}

export interface AddLoanWithdrawal {
  loanId: string;
  transactionRefNo: string;
  amount: number;
  withdrawalAt: string;
  medium: PaymentMediumType;
  txnFeeInAmount: number;
}

export interface AddTransactionWithdrawal {
  utr: string;
  amount: number;
  onDate: string;
}

export interface AddTransactionChargeInterest {
  onDate: string;
}

export interface AddTransactionTDSCreditAndRepayment {
  amount: number;
  paymentRefId: string;
  source: string;
  paidAt: string;
}

export interface addWCTLoanDisbursal {
  date: string;
  disbursedAmount: number;
  transactionRefNo: string;
  bankAccountNo: string;
  ifscCode: string;
}

export interface AddWCTLTdsCredit {
  amount: number;
  paymentRefId: string;
  source: string;
  paidAt: string;
}

export interface AddWCTLRepayment extends AddWCTLTdsCredit {}

export type TransactionType = "CREDIT" | "DEBIT";

export interface Transaction {
  _id: string;
  loanId: string;
  transactionRefNo?: string;
  transactionType: TransactionType;
  amount: number;
  transactedAt: string;
  medium: PaymentMediumType;
  txnFeeInAmount?: number;
  deletedAt?: string | null;
}

export type LoanType =
  | "TERM_LOAN"
  | "RETAIL_OD"
  | "COMMERCIAL_OD"
  | "WORKING_CAPITAL_TERM_LOAN";

export enum LoanStatusEnum {
  APPROVED = "APPROVED",
  DISBURSAL_PENDING = "DISBURSAL_PENDING",
  DISBURSED = "DISBURSED",
  OVERDUE = "OVERDUE",
  PRE_CLOSED = "PRE_CLOSED",
  CLOSED = "CLOSED",
  WRITE_OFF = "WRITE_OFF",
}

export enum TermLoanApplicationStatus {
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  IN_PROGRESS = "IN_PROGRESS",
  USER_DOC_UPLOAD_PENDING = "USER_DOC_UPLOAD_PENDING",
  VERIFICATION_PENDING = "VERIFICATION_PENDING",
  AGREEMENT_PENDING = "AGREEMENT_PENDING",
  ENACH_PENDING = "ENACH_PENDING",
  EXPIRED = "EXPIRED",
}

export interface DisbursalDetails {
  date: string;
  disbursedAmount: number;
  processingFees: number;
  transactionRefNo: string;
  bankAccountNo: string;
  ifscCode: string;
  bankName: string;
}

export interface BaseLoan {
  _id: string;
  userId?: string;
  enterpriseId?: string;
  appId: string;
  irpa: number;
  pf: number;
  pfPostGst: number;
  loanType: LoanType;
  status: LoanStatusEnum;
  createdAt?: string;
  source?: string;
  applicantType?: ApplicantType;
  user?: {
    _id?: string;
    employerName?: string;
    employeeName?: string;
  };
}

export interface LoanDisbursalPayload {
  loanId: string;
  date: string;
  transactionRefNo: string;
  disbursedAmount: number;
  processingFees: number;
  bankAccountNo: string;
  ifscCode: string;
  bankName: string;
}

export enum RoutePaths {
  DASHBOARD = "/",
  USERS = "/users",
  INDIVIDUAL_USERS = "/users/individual",
  ENTERPRISE_USERS = "/enterprise",
  APPLICATIONS = "/applications",
  LOANS = "/loans",
  REPORTS = "/reports",
  PROFILE = "/profile",
  AGREEMENTS = "/agreements",
  COMMERCIAL_LOAN_APPLICATION = "/commercial-loan-application",
  WCTL_LOAN_APPLICATION = "/wctl-loan-application",
  INVOICE = "/invoice",
  PORTFOLIO = "/portfolio",
  REFUND_APPROVAL = "/refund-approval",
  WORKING_CAPITAL_TERM_LOAN = "/wctl-loan",
}

export type SvgIconType = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

export interface NavItemProps {
  name: string;
  path: RoutePaths;
  exact?: boolean;
  Icon?: SvgIconType;
  dropdown?: NavItemProps[];
}

export interface FieldLabel {
  key: string;
  label: string;
  alignItem?: "start" | "center" | "end";
  prefix?: string;
  suffix?: string;
  wrap?: "pre-wrap";
  // TODO: Define proper types
  formatMethod?: (value: any) => any;
  SuffixComponent?: ReactNode;
}

export enum ExternalApiType {
  PAN_VERIFICATION = "PAN_VERIFICATION",
  BANK_ACCOUNT_VERIFICATION = "BANK_ACCOUNT_VERIFICATION",
}

export interface ExternallySourcedBankAccInfo {
  accountNo: string;
  ifsc: string;
  accountHolderName: string | undefined;
  verifiedAt: string;
  verifiedFromSource: string | undefined;
  remarks: string;
}

export interface ExternallySourcedPanInfo {
  pan: string;
  panHolderName: string | undefined;
  verifiedAt: string;
  verifiedFromSource: string | undefined;
  remarks: string;
}

export interface SearchFilter {
  name: string;
  values: string[];
}

// Input function props
export interface Text {
  label?: string;
  error?: string;
  value: string | number;
  disabled?: boolean;
  placeholder?: string;
  fieldName: string;
  onChange: (
    fieldName: string,
    value: string | number,
    valueType: string
  ) => void;
  type: string;
  valueType: string;
}

export interface DateRange {
  startDate: string;
  endDate: string;
}

export enum NotifierBgColor {
  ERROR = "red",
  WARNING = "orange",
  SUCCESS = "green",
}

export enum FileType {
  IMG_PNG = "image/png",
  IMG_JPEG = "image/jpeg",
  IMG_GIF = "image/gif",
  APP_PDF = "application/pdf",
  ZIP = "application/zip",
  APP_JSON = "application/json",
  TEXT_JSON = "text/json",
  UNKNOWN = "Unknown",
  ANY = "*",
}

export enum ApplicantType {
  INDIVIDUAL = "INDIVIDUAL",
  ENTERPRISE = "ENTERPRISE",
}

export enum ReportPeriod {
  TODAY = "TODAY",
  LAST_7_DAYS = "LAST_7_DAYS",
  LAST_15_DAYS = "LAST_15_DAYS",
  LAST_MONTH = "LAST_MONTH",
  CUSTOM = "CUSTOM",
}

export enum RefundApprovalStatus {
  CREATED = "CREATED",
  FAILED = "FAILED",
  APPROVAL_PENDING = "APPROVAL_PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  PAYOUT_INITIATED = "PAYOUT_INITIATED",
  PAYOUT_DISBURSED = "PAYOUT_DISBURSED",
  PAYOUT_REVERSED = "PAYOUT_REVERSED",
  PAYOUT_FAILED = "PAYOUT_FAILED",
}

//INFO: BE return type
//INFO: https://github.com/refyne/lms-server/blob/3093d9e2a3ecb6834d702eafc94604cb34472c37/src/bank-statement/finbox-client.service.ts#L78
//TODO: add types for monthly_analysis
export interface MonthlyAnalysis {
  entity_id: string;
  progress: Array<MonthlyAnalysisProgressField>;
  monthly_analysis: MonthlyAnalysisStatementsFields;
}

export interface MonthlyAnalysisProgressField {
  status: "processing" | "completed" | "failed" | null;
  statement_id: string;
  source: string;
  message: string | null;
}

export type MonthlyAnalysisStatementsFields = Record<
  string,
  Record<string, unknown>
>;
export interface PasswordFile {
  name: string;
  type: string;
  password: string;
}

export type ArgsAsStringOrObject = [string | Record<string, boolean>];
export type ArgsAsStringObjectTuple = [
  string,
  Record<string, boolean> | undefined
];

export type InvoiceStatusType = "PENDING" | "SUCCESS";

export interface FilterUserDocType extends UserDocType {
  userDocCategory: UserDocCategory | "LOAN_APPLICATION_DOCS" | "OTHERS";
}

export interface AddVkycDetailsBody {
  userId: string;
  location: {
    latitude: number;
    longitude: number;
  };
  documentsInfo: [
    {
      url: string;
      mimeType: string;
      name: string;
    }
  ];
  agentReviewDetails: {
    agentDecision: string;
    agentDetails: {
      name: string;
      email: string;
    };
    decisionedAt: string;
    comments: string;
  };
}

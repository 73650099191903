import React, { ChangeEvent, useState } from "react";
import { useDispatch } from "react-redux";
import { createCommercialOdApplication } from "../../../../actions/application.action";
import Alert from "../../../../components/alert";
import Button from "../../../../components/button";
import DatafieldSection, {
  DatafieldLabel,
} from "../../../../components/datafield-section";
import {
  HorizontalSelect,
  InputField,
} from "../../../../components/input-functions";
import { EnterpriseDetailsV1 } from "../../../../dto/enterprise.dto";
import { ReactComponent as TickIcon } from "../../../../images/tick.svg";
import dispatch from "../../../../middleware";
import {
  CommercialOdLoanProductCategoryEnum,
  NewCommercialODApplication,
} from "../../../../models";
import { splitKeyWithSpace } from "../../../../utils";

interface CommericalOdNewApplicationProps {
  singleEnterprise: EnterpriseDetailsV1;
  onSuccess: (() => Promise<any>) | undefined;
}

const formDataDefaultValue: NewCommercialODApplication = {
  loanProductCategory: CommercialOdLoanProductCategoryEnum.DROPLINE_OD,
  enterpriseId: "",
  sanctionedAmount: 0,
  drawdownRepaymentPeriodInDays: 0,
  interestRepaymentPeriodInDays: 0,
  irpa: 0,
  pfPostGst: 0,
  onDate: new Date(),
  discountDays: 0,
  invoiceAmount: 0,
  tdsDeducted: 0,
  invoiceDate: new Date(),
  invoiceId: "",
  vendorId: "",
  vendorName: "",
};

const labels: DatafieldLabel[] = [
  {
    items: [
      { key: "name", label: "Enterprise :" },
      { key: "_id", label: "Enterprise ID :" },
      {
        key: "pan",
        label: "PAN :",
      },
      {
        key: "bankAccountInfo.accountNumber",
        label: "Account No :",
      },
      {
        key: "bankAccountInfo.ifsc",
        label: "IFSC :",
      },
    ],
  },
];

export default function CommericalOdNewApplication({
  singleEnterprise,
  onSuccess,
}: CommericalOdNewApplicationProps) {
  const storeDispatch = useDispatch();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] =
    useState<NewCommercialODApplication>(formDataDefaultValue);
  const [selectedLoanType, setSelectedLoanType] =
    useState<CommercialOdLoanProductCategoryEnum>(
      formDataDefaultValue.loanProductCategory
    );

  async function handleSubmit(e: ChangeEvent<HTMLFormElement>) {
    e.preventDefault();
    setIsSubmitLoading(true);
    try {
      const payload: NewCommercialODApplication = {
        loanProductCategory: selectedLoanType,
        enterpriseId: singleEnterprise._id,
        sanctionedAmount: Number(formData.sanctionedAmount),
        drawdownRepaymentPeriodInDays: Number(
          formData.drawdownRepaymentPeriodInDays
        ),
        interestRepaymentPeriodInDays: Number(
          formData.interestRepaymentPeriodInDays
        ),
        irpa: Number(formData.irpa),
        pfPostGst: Number(formData.pfPostGst),
        onDate: formData.onDate,
        ...(formData.tdsDeducted && {
          tdsDeducted: Number(formData.tdsDeducted),
        }),
        ...(formData.invoiceDate && {
          invoiceDate: formData.invoiceDate,
        }),
        ...(formData.discountDays && {
          discountDays: Number(formData.discountDays),
        }),
        ...(formData.invoiceAmount && {
          invoiceAmount: Number(formData.invoiceAmount),
        }),
        ...(formData.invoiceId && { invoiceId: formData.invoiceId }),
        ...(formData.vendorId && { vendorId: formData.vendorId }),
        ...(formData.vendorName && { vendorName: formData.vendorName }),
      };

      const response = await dispatch(
        storeDispatch,
        createCommercialOdApplication(payload)
      );

      if (typeof onSuccess === "function") onSuccess();

      setSuccessMessage(
        `Commercial OD Application # ${response?._id} created successfully`
      );
    } catch (error) {
      setErrorMessage(error.message || "Something went wrong, Try again later");
    } finally {
      setIsSubmitLoading(false);
    }
  }

  function handleChange(
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    transformValue?: (value: string) => void
  ) {
    const value = e.target.value;
    const inputMode = e.target.inputMode;
    if (inputMode === "numeric" && Number.isNaN(Number(value))) return;

    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: transformValue ? transformValue(value) : value,
    }));
  }

  function handleLoanTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const { value } = event.currentTarget;
    setSelectedLoanType(value as CommercialOdLoanProductCategoryEnum);
  }

  if (successMessage) {
    return (
      <div className="d-flex flex-column align-items-center">
        <TickIcon color="#5BC97C" />
        <p style={{ marginTop: "20px", marginBottom: "0" }}>{successMessage}</p>
      </div>
    );
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <DatafieldSection labels={labels} data={singleEnterprise} />
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-7">
          <form onSubmit={handleSubmit}>
            <Alert
              type="danger"
              message={errorMessage}
              canDismiss={true}
              onDismissClick={() => setErrorMessage("")}
            />
            <CreateCommercialOdApplicationForm
              isSubmitLoading={isSubmitLoading}
              singleEnterprise={singleEnterprise}
              formData={formData}
              onChange={handleChange}
              onLoanTypeChange={handleLoanTypeChange}
            />
          </form>
        </div>
      </div>
    </>
  );
}

interface CreateCommercialOdApplicatioinFormProps {
  isSubmitLoading: boolean;
  singleEnterprise: EnterpriseDetailsV1;
  formData: NewCommercialODApplication;
  onChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    transformValue?: (value: string) => void
  ) => void;
  onLoanTypeChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

function CreateCommercialOdApplicationForm({
  isSubmitLoading,
  singleEnterprise,
  formData,
  onChange,
  onLoanTypeChange,
}: CreateCommercialOdApplicatioinFormProps) {
  return (
    <>
      <InputField
        label="Enterprise ID"
        type="text"
        name="enterpriseId"
        placeholder="Enterprise ID"
        value={singleEnterprise._id}
        disabled
        required
      />

      <HorizontalSelect
        label="Loan Type"
        placeholder="Select loan type"
        options={Object.values(CommercialOdLoanProductCategoryEnum).map(
          (value) => ({
            label: splitKeyWithSpace(value),
            value,
          })
        )}
        onChange={onLoanTypeChange}
      />

      <InputField
        label="Vendor Id"
        type="text"
        name="vendorId"
        placeholder="Vendor Id"
        value={formData?.vendorId}
        onChange={onChange}
      />

      <InputField
        label="Vendor Name"
        type="text"
        name="vendorName"
        placeholder="Vendor Name"
        value={formData?.vendorName}
        onChange={onChange}
      />

      <InputField
        label="Invoice Id"
        type="text"
        name="invoiceId"
        placeholder="Invoice Id"
        value={formData?.invoiceId}
        onChange={onChange}
      />

      <InputField
        label="Invoice Amount"
        type="text"
        inputMode="numeric"
        name="invoiceAmount"
        placeholder="Invoice Amount"
        value={formData?.invoiceAmount}
        onChange={onChange}
      />

      <InputField
        label="Invoice Date"
        type="date"
        name="invoiceDate"
        placeholder="Invoice Date"
        value={formData.invoiceDate?.toISOString().split("T")[0]}
        onChange={(e) => onChange(e, (value) => new Date(value))}
      />

      <InputField
        label="Discount Days"
        type="text"
        inputMode="numeric"
        name="discountDays"
        placeholder="Discount Days"
        value={formData?.discountDays}
        onChange={onChange}
      />

      <InputField
        label="Sanctioned Amount"
        type="text"
        inputMode="numeric"
        name="sanctionedAmount"
        placeholder="Sanctioned Amount"
        value={formData.sanctionedAmount}
        onChange={onChange}
        required
      />

      <InputField
        label="Draw Down Repayment Period In Days"
        type="text"
        inputMode="numeric"
        name="drawdownRepaymentPeriodInDays"
        placeholder="Draw Down Repayment Period In Days"
        value={formData.drawdownRepaymentPeriodInDays}
        onChange={onChange}
        required
      />

      <InputField
        label="Interest Repayment Period In Days"
        type="text"
        inputMode="numeric"
        name="interestRepaymentPeriodInDays"
        placeholder="Interest Repayment Period In Days"
        value={formData.interestRepaymentPeriodInDays}
        onChange={onChange}
        required
      />

      <InputField
        label="Irpa (%)"
        type="text"
        inputMode="numeric"
        name="irpa"
        placeholder="Irpa"
        value={formData.irpa}
        onChange={onChange}
        required
      />

      <InputField
        label="Pf Post Gst"
        type="text"
        inputMode="numeric"
        name="pfPostGst"
        placeholder="Pf Post Gst"
        value={formData.pfPostGst}
        onChange={onChange}
        required
      />

      <InputField
        label="TDS Deducted"
        type="text"
        inputMode="numeric"
        name="tdsDeducted"
        placeholder="TDS Deducted"
        value={formData.tdsDeducted}
        onChange={onChange}
      />

      <InputField
        label="On Date"
        type="Date"
        name="onDate"
        placeholder="On Date"
        value={formData.onDate.toISOString().split("T")[0]}
        onChange={(e) => onChange(e, (value) => new Date(value))}
        required
      />

      <Button type="submit" loading={isSubmitLoading}>
        Create application
      </Button>
    </>
  );
}

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { getWCTLApplicationBySearch } from "../../../actions/application.action";
import {
  getEnterpriseByIdV1,
  getSingleEnterpriseApplicationById,
  getSingleEnterpriseLoanById,
  verifyEnterprise,
} from "../../../actions/enterprise.action";
import { getWCTLoansBySearch } from "../../../actions/loan.action";
import Alert from "../../../components/alert";
import Badge from "../../../components/badge";
import DatafieldSection, {
  DatafieldLabel,
} from "../../../components/datafield-section";
import Modal from "../../../components/modal";
import { TableColumns } from "../../../components/table";
import {
  SingleEntepriseWctlApplication,
  SingleEntepriseWctlLoan,
} from "../../../dto";
import {
  EnterpriseDetailsV1,
  SingleEnterpriseApplicationStatus,
  SingleEnterpriseV1Applications,
  SingleEnterpriseV1Loans,
} from "../../../dto/enterprise.dto";
import dispatch from "../../../middleware";
import { RoutePaths } from "../../../models";
import { ReduxState } from "../../../reducers";
import { splitKeyWithSpace } from "../../../utils";
import "../enterpriseStyles.css";
import CommercialOdApplication from "./application/commercialOdApplication";
import WctlApplication from "./application/wctlApplicaion";
import BasicInformation from "./basicInformation";
import EditEnterpriseForm from "./editEnterprise";
import SingleEnterpriseLoansOrApplicationTable from "./singleEnterpriseLoansOrApplicationTable";
import SingleEnterpriseWctlApplicationTable from "./singleEnterpriseWctlApplicationTable";
import SingleEnterpriseWctLoanTable from "./singleEnterpriseWctloanTable";

const TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_LOANS: TableColumns[] = [
  { field: "_id", label: "Loan ID", style: { textAlign: "center" } },
  { field: "irpa", label: "Irpa", style: { textAlign: "center" } },
  {
    field: "sanctionedAmount",
    label: "Sanctioned Amount",
    style: { textAlign: "center" },
  },
];

const TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_APPLICATIONS: TableColumns[] = [
  { field: "_id", label: "Application ID", style: { textAlign: "center" } },
  { field: "status", label: "Status", style: { textAlign: "center" } },
  {
    field: "sanctionedAmount",
    label: "Sanctioned Amount",
    style: { textAlign: "center" },
  },
];

const TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_WCTL_APPLICATIONS: TableColumns[] = [
  { field: "_id", label: "Application ID", style: { textAlign: "center" } },
  { field: "irpa", label: "IRPA", style: { textAlign: "center" } },
  {
    field: "approvedAmount",
    label: "Approved Amount",
    style: { textAlign: "center" },
  },
];

const TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_WCTL_LOANS: TableColumns[] = [
  { field: "appId", label: "Application ID", style: { textAlign: "center" } },
  { field: "_id", label: "Loan ID", style: { textAlign: "center" } },
  { field: "irpa", label: "IRPA", style: { textAlign: "center" } },
  {
    field: "approvedAmount",
    label: "Approved Amount",
    style: { textAlign: "center" },
  },
];

function getTableDataForSingleEnterpriseWctlLoan(
  data: SingleEntepriseWctlLoan[] | null
) {
  if (!data) return [];
  return data.map(({ appId, approvedAmount, _id, irpa }) => ({
    _id: <Link to={`${RoutePaths.LOANS}/wctl-loan/${_id}`}>{_id}</Link>,
    appId: <Link to={`/wctl-loan-application/${appId}`}>{appId}</Link>,
    approvedAmount,
    irpa,
  }));
}

function getTableDataForSingleEnterpriseWctlApplicatioin(
  data: SingleEntepriseWctlApplication[] | null
) {
  if (!data) return [];
  return data.map(({ _id, approvedAmount, irpa }) => ({
    _id: <Link to={`/wctl-loan-application/${_id}`}>{_id}</Link>,
    irpa,
    approvedAmount,
  }));
}

function getTableDataForSingleEnterpriseLoan(
  data: SingleEnterpriseV1Loans | null
) {
  if (!data) return [];
  return data.loans.map(({ _id, irpa, sanctionedAmount }) => ({
    _id: <Link to={`${RoutePaths.LOANS}/commercial/${_id}`}>{_id}</Link>,
    irpa,
    sanctionedAmount,
  }));
}

function getTableDataForSingleEnterpriseApplication(
  data: SingleEnterpriseV1Applications | null
) {
  if (!data) return [];
  return data.applications.map(({ _id, status, sanctionedAmount }) => ({
    _id: (
      <Link to={`${RoutePaths.COMMERCIAL_LOAN_APPLICATION}/${_id}`}>{_id}</Link>
    ),
    status: getStatusBadge(status),
    sanctionedAmount,
  }));
}

function getStatusBadge(status: SingleEnterpriseApplicationStatus) {
  if (status === SingleEnterpriseApplicationStatus.APPROVED)
    return <Badge type="success">Approved</Badge>;
  if (status === SingleEnterpriseApplicationStatus.IN_PROGRESS)
    return <Badge type="pending">In Progress</Badge>;
  if (status === SingleEnterpriseApplicationStatus.REJECTED)
    return <Badge type="reject">Rejected</Badge>;
  return null;
}

export default function SingleEnterpriseScreen() {
  const { id } = useParams();
  const storeDispatch = useDispatch();
  const singleEnterprise: EnterpriseDetailsV1 = useSelector(
    (state: ReduxState) => state.enterprise.singleEnterpriseV1
  );
  const singleEnterpriseLoans: SingleEnterpriseV1Loans | null = useSelector(
    (state: ReduxState) => state.enterprise.singleEnterpriseV1Loans
  );
  const singleEnterpriseApplications: SingleEnterpriseV1Applications | null =
    useSelector(
      (state: ReduxState) => state.enterprise.singleEnterpriseV1Applications
    );
  const singleEnterpriseWctLoans: SingleEntepriseWctlLoan[] | null =
    useSelector((state: ReduxState) => state.loan.loans);
  const singleEnterpriseWctlApplication:
    | SingleEntepriseWctlApplication[]
    | null = useSelector((state: ReduxState) => state.application.applications);
  const loading = useSelector((state: ReduxState) => state.enterprise.loading);
  const [successMsg, setSuccessMsg] = useState("");
  const [isEditEnterpriseModalActive, setIsEditEnterpriseModalActive] =
    useState(false);

  const relationshipDetailLabels: DatafieldLabel[] = [
    {
      items: [
        {
          key: "relationshipInfo.relationType",
          label: "Relationship type :",
          formatMethod: splitKeyWithSpace,
        },
        {
          key: "relationshipInfo.relationship",
          label: "Relationship :",
          formatMethod: splitKeyWithSpace,
        },
        {
          key: "relationshipInfo.metadata.dateOfBirth",
          label: "Date of birth :",
        },
        {
          key: "relationshipInfo.metadata.pan",
          label: "PAN :",
        },
      ],
    },
    {
      items: [
        {
          key: "relationshipInfo.metadata.addressLine1",
          label: "Address :",
        },
        {
          key: "relationshipInfo.metadata.pincode",
          label: "Pincode :",
        },
        {
          key: "relationshipInfo.metadata.stateOrUnionTerritoryName",
          label: "State/UT :",
        },
      ],
    },
  ];

  const bankDetailLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "bankAccountInfo.accountNumber", label: "Account No :" },
        {
          key: "bankAccountInfo.accountType",
          label: "Account Type :",
          formatMethod: splitKeyWithSpace,
        },
      ],
    },
    {
      items: [
        {
          key: "bankAccountInfo.ifsc",
          label: "IFSC :",
        },
      ],
    },
  ];

  const basicDetailsLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "name", label: "Name: " },
        { key: "pan", label: "PAN: " },
        { key: "gstin", label: "GST: " },
        {
          key: "legalConstitution",
          label: "Enterprise legal consitution: ",
          formatMethod: splitKeyWithSpace,
        },
      ],
    },
    {
      items: [
        {
          key: "industryType",
          label: "Enterprise Type: ",
          formatMethod: splitKeyWithSpace,
        },
        {
          key: "businessCategory",
          label: "Enterprise Category: ",
        },
        {
          key: "assessmentAgency",
          label: "Assessment Agency: ",
        },
      ],
    },
  ];

  const communicationDetailsLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "address.state", label: "State:" },
        {
          key: "address.localityInfo",
          label: "City:",
          formatMethod: splitKeyWithSpace,
        },
        { key: "address.pincode", label: "Pincode:" },
        {
          key: "address.locationType",
          label: "Location Type:",
          formatMethod: splitKeyWithSpace,
        },
      ],
    },
  ];

  const VPADetailsLabels: DatafieldLabel[] = [
    {
      items: [
        { key: "virtualPaymentAccount.accountNumber", label: "VPA: " },
        { key: "virtualPaymentAccount.ifsc", label: "IFSC: " },
      ],
    },
  ];
  async function onUserVerify(id: string) {
    try {
      await dispatch(storeDispatch, verifyEnterprise(id));
      setSuccessMsg("Verified successfully");
      dispatch(storeDispatch, getEnterpriseByIdV1(id));
    } catch (_) {}
  }

  function onEditClick() {
    setIsEditEnterpriseModalActive(true);
  }

  useEffect(() => {
    if (!id) return;
    dispatch(storeDispatch, getEnterpriseByIdV1(id));
    dispatch(storeDispatch, getSingleEnterpriseLoanById(id));
    dispatch(storeDispatch, getSingleEnterpriseApplicationById(id));
    dispatch(
      storeDispatch,
      getWCTLoansBySearch({
        pageNo: 1,
        enterpriseId: id,
        numberOfEntitiesPerPage: 1000,
      })
    );
    dispatch(
      storeDispatch,
      getWCTLApplicationBySearch({
        pageNo: 1,
        numberOfEntitiesPerPage: 1000,
        enterpriseId: id,
      })
    );
  }, []);

  return (
    <div>
      <Alert
        type="success"
        message={successMsg}
        canDismiss
        onDismissClick={() => setSuccessMsg("")}
      />
      <BasicInformation
        enterprise={singleEnterprise}
        isLoading={loading}
        onUserVerify={onUserVerify}
        onEditClick={onEditClick}
      />
      <DatafieldSection
        title="Basic Details"
        labels={basicDetailsLabels}
        data={singleEnterprise}
        withinBox
      />
      <DatafieldSection
        title="Communication Details"
        labels={communicationDetailsLabels}
        data={singleEnterprise}
        withinBox
      />
      <DatafieldSection
        title="Relationship"
        labels={relationshipDetailLabels}
        data={singleEnterprise}
        withinBox
      />
      <DatafieldSection
        title="Primary bank account"
        labels={bankDetailLabels}
        data={singleEnterprise}
        withinBox
      />
      <DatafieldSection
        title="VPA: NBFC Payment Receivable Account"
        labels={VPADetailsLabels}
        data={singleEnterprise}
        withinBox
      />
      <CommercialOdApplication
        title="Commercial OD Applications"
        onSuccess={() =>
          dispatch(storeDispatch, getSingleEnterpriseApplicationById(id))
        }
      />
      <WctlApplication
        title="WCTL Application"
        singleEnterprise={singleEnterprise}
      />
      <SingleEnterpriseLoansOrApplicationTable
        loading={loading}
        title="Commercial OD Applications"
        data={getTableDataForSingleEnterpriseApplication(
          singleEnterpriseApplications
        )}
        columns={TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_APPLICATIONS}
      />
      <SingleEnterpriseLoansOrApplicationTable
        loading={loading}
        title="Commercial OD Loans"
        data={getTableDataForSingleEnterpriseLoan(singleEnterpriseLoans)}
        columns={TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_LOANS}
      />
      <SingleEnterpriseWctlApplicationTable
        loading={loading}
        title="Working Capital Term Loan Application"
        data={getTableDataForSingleEnterpriseWctlApplicatioin(
          singleEnterpriseWctlApplication
        )}
        columns={TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_WCTL_APPLICATIONS}
      />
      <SingleEnterpriseWctLoanTable
        loading={loading}
        title="Working Capital Term Loan"
        data={getTableDataForSingleEnterpriseWctlLoan(singleEnterpriseWctLoans)}
        columns={TABLE_COLUMNS_FOR_SINGLE_ENTERPRISE_WCTL_LOANS}
      />
      <Modal
        open={isEditEnterpriseModalActive}
        title="Edit Enterprise"
        size="large"
        hasSubmitButton={false}
        hasCancelButton={false}
        onClose={() => setIsEditEnterpriseModalActive(false)}
      >
        <EditEnterpriseForm
          data={singleEnterprise}
          onSuccess={() => dispatch(storeDispatch, getEnterpriseByIdV1(id))}
        />
      </Modal>
    </div>
  );
}
